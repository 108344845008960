(function () {
    'use strict';
    var app = angular.module('App.Elements');

    app.directive('departmentSelector', ['$http', '$filter',
        function ($http) {
            return {
                restrict: 'E',
                template: require('./DepartmentSelector.html'),
                scope: {
                    required: '<',
                    model: '=',
                    settings: '=',
                    multiple: '<',
                    disabled: '<',
                    resetDepartments: '=?'
                },
                controller: ['$scope',
                    function ($scope) {

                        $scope.model = $scope.model || [];
                        $scope.multiple = $scope.multiple || false;
                        $scope.isLoading = true;
                        $scope.handleChange = handleChange;
                        $scope.resetDepartments = resetDepartments;

                        // Init
                        InitDirective();

                        function InitDirective() {
                            // Prepare list
                            var settings = {};

                            if ($scope.settings) {
                                settings = $scope.settings;

                                const departments = $scope.settings.departments || [];
                                const selectedDepartments = Array.isArray($scope.model) ? $scope.model : [$scope.model];

                                settings.departments = [...new Set([...departments, ...selectedDepartments])];
                            }

                            settings.selected = $scope.multiple ? $scope.model : [$scope.model];

                            $http.post('/Directives/DepartmentSelector/GetSelector', settings).then(function (response) {
                                // Success
                                $scope.departments = convertToList(response.data.Departments).filter(d => d.IsSelectable);
                                $scope.departments.model = $scope.multiple ?
                                    $scope.departments.filter(d => $scope.model.find(id => d.DepartmentId === id)) :
                                    $scope.departments.filter(d => d.DepartmentId === $scope.model);

                                $scope.isLoading = false;
                            }, function () {
                                // Failed
                                $scope.isLoading = false;
                            });
                        }

                        function handleChange() {
                            if (!$scope.departments.model) {
                                $scope.model = null;
                                return;
                            }
                            
                            if ($scope.multiple) {
                                $scope.model = $scope.departments.model.map(department => department.DepartmentId);
                            } else {
                                $scope.model = $scope.departments.model[0] && $scope.departments.model[0].DepartmentId;
                            }
                        }

                        function convertToList(tree) {
                            const result = [];
                            getItems(tree)

                            function getItems(children, parentName = "", prefix = "") {
                                children.forEach(department => {
                                    result.push({
                                        ...department.Department,
                                        ParentName: parentName,
                                        Name: `${prefix} ${department.Department.Name}`,
                                        IsSelectable: department.IsSelectable
                                    });

                                    if (department.Children) {
                                        getItems(department.Children, department.Name, prefix + '-');
                                    }
                                });
                            }

                            return result;
                        }

                        function resetDepartments() {
                            $scope.$$childTail.reset && $scope.$$childTail.reset();
                        }
                    }
                ]
            }
        }
    ]);
})();