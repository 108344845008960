(() => {
    'use strict';

    angular
        .module('App.Survey')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('surveySingleDepartment', {
                    parent: 'base',
                    url: '/survey-single-department/:token/:extra',
                    template: `<survey-manager-tab class="view-page" show-single-department="true" survey-token="surveyToken" department-token="departmentToken" is-visible="true"></survey-manager-tab>`,
                    params: {
                        backState: null
                    },
                    resolve: {},
                    controller: 'SurveySingleDepartmentPageController'
                });
        }])
        .controller('SurveySingleDepartmentPageController', ['$scope', '$stateParams', 'events', 'Page', SurveySingleDepartmentPageController]);

    function SurveySingleDepartmentPageController($scope, $stateParams, events, Page) {
        $scope.surveyToken = $stateParams.token;
        $scope.departmentToken = $stateParams.extra;

        if ($stateParams.backState !== null) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, null, true, {tab: $stateParams.backState.tab});
        } else {
            Page.showBackButton('survey', null, $stateParams.token, null, null, true, {tab: 'manager'});
        }

        $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());
    }
})();