(() => {
    'use strict';

    angular
        .module('App.Survey')
        .factory('SurveyService', ['$http', '$translate', '$filter', 'ActionPopupService', SurveyService]);

    function SurveyService($http, $translate, $filter, ActionPopupService) {
        const
            TEXTS = {
                NoQuizzes: 'SURVEY.NO_QUIZZES',
                QuizAnswered: 'SURVEY.NO_QUIZZES',
                QuizDoneTitle: 'SURVEY.QUIZ_DONE_TITLE',
                QuizDoneText: 'SURVEY.QUIZ_DONE_TEXT',
                SurveyDoneTitle: 'SURVEY.SURVEY_DONE_TITLE',
                SurveyDoneText: 'SURVEY.SURVEY_DONE_TEXT'
            },
            TAB_TRANSLATIONS = {
                'SURVEY': $filter('translate')('SURVEY.SURVEY_TAB'),
                'MANAGER': $filter('translate')('SURVEY.MANAGER_VIEW_TAB'),
                'ACTIVE': $filter('translate')('SURVEY.ACTIVE_SURVEYS_TAB'),
                'EXPIRED': $filter('translate')('SURVEY.EXPIRED_SURVEYS_TAB')
            },
            RATING_IMAGES_BASE_URL = '/Content/Graphics/Survey/',
            RATING = {
                null: 'na', 
                1: 'negative',
                2: 'neutral',
                3: 'positive'
            },
            DRIVER_IMAGES_BASE_URL = '/Content/Graphics/Survey/SurveyDriverIcons/',
            DRIVER_IMAGES = {
                1: 'office-building.png',
                2: 'star.png',
                3: 'spiral-calendar.png',
                4: 'laptop.png',
                5: 'speech-balloon.png',
                6: 'person-running.png',
                7: 'balance-scale.png',
                8: 'handshake.png',
                9: 'busts-in-silhouette.png',
                10: 'raising-hands.png',
                11: 'smiling-face-with-open-hands.png',
                12: 'bust-in-silhouette.png',
                13: 'raising-hands.png',
                14: 'handshake.png',
                15: 'speech-balloon.png',
                16: 'glowing-star.png',
                17: 'grinning-face-with-smiling-eyes.png',
                18: 'glowing-star.png',
                19: 'star-struck.png',
                20: 'star.png',
                21: 'puzzle-piece.png',
                22: 'gem-stone.png',
                23: 'books.png',
                24: 'chart-increasing.png',
                25: 'glowing-star.png',
                26: 'trophy.png',
                27: 'star.png',
                28: 'handshake.png',
                29: 'speech-balloon.png',
                30: 'balance-scale.png',
                31: 'rocket.png',
                32: 'bullseye.png',
                33: 'bar-chart.png',
                34: 'clapping-hands.png',
                35: 'military-medal.png',
                36: 'flexed-biceps.png',
                37: 'bullseye.png',
                38: 'envelope.png',
                39: 'speech-balloon.png',
                40: 'envelope-with-arrow.png',
                41: 'hammer-with-wrench.png',
                42: 'hourglass-not-done.png',
                43: 'bar-chart.png',
                null: 'question.png'
            };

        return {
            getTranslations: getTranslations,
            getManagerTabData: getManagerTabData,
            initParticipationRateChart: initParticipationRateChart,
            getQuestionStatistics: getQuestionStatistics,
            getDriverIcon: getDriverIcon,
            getRatingClass: getRatingClass,
            getRatingIcon: getRatingIcon,
            showFinishPopup: showFinishPopup,
            TAB_TRANSLATIONS,
            RATING
        }

        function getTranslations(settings, menuTitle) {
            const translations = {};

            translations.NoQuizzes = settings.NoSurveyText ?
                settings.NoSurveyText :
                $filter('translate')(TEXTS.NoQuizzes).replace('[PAGETITLE]', menuTitle);

            translations.QuizAnswered = settings.SurveyAnsweredText ?
                settings.SurveyAnsweredText :
                $filter('translate')(TEXTS.QuizAnswered).replace('[PAGETITLE]', menuTitle);

            translations.QuizDoneTitle = settings.SurveyDoneTitle ?
                settings.SurveyDoneTitle :
                $filter('translate')(TEXTS.QuizDoneText);

            translations.QuizDoneText = settings.SurveyDoneText ?
                settings.SurveyDoneText :
                $filter('translate')(TEXTS.QuizDoneText);

            translations.SurveyDoneTitle = settings.SurveyDoneTitle ?
                settings.SurveyDoneTitle :
                $filter('translate')(TEXTS.SurveyDoneTitle);

            translations.SurveyDoneText = settings.SurveyDoneText ?
                settings.SurveyDoneText :
                $filter('translate')(TEXTS.SurveyDoneText);

            return translations
        }

        function getManagerTabData(isSingle, surveyToken, departmentToken) {
            const url = isSingle ?
                `/survey/getDepartmentStatistics?surveyToken=${surveyToken}&departmentToken=${departmentToken}` :
                `/survey/getDepartments?surveyToken=${surveyToken}`

            return $http.get(url).then(resp => resp.data);
        }

        function getQuestionStatistics(surveyToken, departmentToken, questionId) {
            return $http
                .get(`/survey/GetQuestionStatistics?surveyToken=${surveyToken}&departmentToken=${departmentToken}&questionId=${questionId}`)
                .then(resp => resp.data);
        }

        function getDriverIcon(id) {
            return DRIVER_IMAGES_BASE_URL + DRIVER_IMAGES[id];
        }

        function getRatingClass(RateType) {
            return RATING[RateType];
        }

        function getRatingIcon(RateType) {
            return RATING_IMAGES_BASE_URL + RATING[RateType] + '.png';
        }

        function showFinishPopup(onFinishClick, translations) {
            ActionPopupService.create(
                '<survey-finish-popup on-finish-click="onFinishClick" translations="translations"></survey-finish-popup>',
                {onFinishClick, translations}
            );
        }

        function initParticipationRateChart(element, participationRate) {
            const options = {
                chart: {
                    type: 'pie',
                    spacing: [0, 0, 0, 0],
                    plotShadow: false,
                    backgroundColor: 'var(--general-background-color)'
                },
                colors: ['rgba(var(--highlight-color-rgb), 1)', 'rgba(var(--highlight-color-rgb), 0.25)'],
                title: {
                    text: ''
                },
                legend: {
                    enabled: false,
                },
                series: [{
                    enableMouseTracking: false,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    data: []
                }],
                credits: {enabled: false},
                plotOptions: {
                    series: {
                        allowPointSelect: true
                    },
                    pie: {
                        innerSize: '67%',
                        size: 150,
                        borderColor: 'var(--general-background-color)',
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            }

            options.series[0].data.push({y: participationRate});
            if (participationRate !== 100) {
                options.series[0].data.push({y: 100 - participationRate});
            } else {
                options.plotOptions.pie.borderWidth = 0;
                options.plotOptions.pie.innerSize = '80%';
            }

            Highcharts.chart(element, options);
        }
    }
})();