(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogSearch', {
            template: require('./CatalogSearchComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$state', '$rootScope', '$scope', '$stateParams', '$filter', 'Page', 'CatalogFactory',
                'CatalogSearchService', CatalogSearchController],
            bindings: {
                settings: '<',
                searchPlaceholder: '<',
                isSearchActive: '=?',
                isSearching: '=?'
            }
        });

    function CatalogSearchController($timeout, $state, $rootScope, $scope, $stateParams, $filter, Page, CatalogFactory,
                                     CatalogSearchService) {
        const ctrl = this;
        let destroyBarcodeWatch, destroySearchStringWatch;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.clearBarcode = clearBarcode;
        ctrl.onSearch = onSearch;
        ctrl.paging = paging;
        ctrl.openItem = openItem;
        ctrl.getSearchPlaceholder = getSearchPlaceholder;

        function init() {
            ctrl.searchQuery = {};
            if ($stateParams.data.showSearch && CatalogSearchService.isSearchActive()) {
                ctrl.searchQuery = CatalogSearchService.getSearchState();
                ctrl.isSearchActive = true;

                if (ctrl.searchQuery.scrollTop) {
                    ctrl.hideResults = true;
                    $timeout(() => {
                        document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                        $timeout(() => {
                            ctrl.hideResults = false;
                        })
                    }, 300);
                }
            }

            initWatchers();
        }

        function initWatchers() {
            destroyBarcodeWatch = $scope.$watch('ctrl.barcode', () => {
                if (ctrl.barcode) {
                    ctrl.searchString = ctrl.barcode;
                    onSearch(ctrl.barcode);
                }
            }, true)

            destroySearchStringWatch = $scope.$watch('ctrl.searchQuery.searchText', (str) => {
                if (str?.length === 0) {
                    onSearch('')
                }
            }, true)
        }

        function onSearch(string) {
            ctrl.noResults = false;
            ctrl.isSearching = true;

            delete ctrl.searchQuery.afterEntityType;
            delete ctrl.searchQuery.afterEntityId;

            if (string?.length) {
                Page.stateGo($state.current?.name, $state.params?.token, $state.params?.extra, $state.params?.backState, {showSearch: true}, false);
                ctrl.isSearchActive = true;
                ctrl.searchQuery.searchText = string;
                CatalogFactory
                    .search(ctrl.searchQuery, ctrl.settings.AccountModuleId)
                    .then(data => {
                        ctrl.isSearching = false;
                        ctrl.searchQuery.afterEntityType = data.BatchLastEntityType;
                        ctrl.searchQuery.afterEntityId = data.BatchLastEntityId;
                        ctrl.searchQuery.items = data.Items;

                        if (ctrl.searchQuery.items.length === 0) {
                            ctrl.noResults = true;
                        }
                    });
            } else {
                ctrl.searchQuery.items = [];
                ctrl.isSearchActive = false;
                ctrl.isSearching = false;
                ctrl.noResults = false;
            }

            $timeout(() => {
                document.querySelector('html').scrollTop = 0;
            });
        }

        function paging() {
            if (!ctrl.isSearching) {
                ctrl.isSearching = true;
                CatalogFactory
                    .search({...ctrl.searchQuery, limit: 10}, ctrl.settings.AccountModuleId)
                    .then(data => {
                        ctrl.searchQuery.afterEntityType = data.BatchLastEntityType;
                        ctrl.searchQuery.afterEntityId = data.BatchLastEntityId;
                        ctrl.searchQuery.items.push(...data.Items);

                        $timeout(() => {
                            ctrl.isSearching = false;
                        })
                    })
                    .catch(() => {
                        ToastFactory.errorTranslated();
                    });
            }
        }

        function clearBarcode() {
            ctrl.barcode = '';
        }

        function openItem(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            CatalogSearchService.setSearchState(ctrl.searchQuery);
            Page.stateGoLinkV2(item.EntityLink);
        }

        function getSearchPlaceholder() {
            let placeholder = $filter('translate')('CONTENT.SEARCH_IN');

            if (ctrl.searchPlaceholder) {
                placeholder += ' ' + $filter('translate')(ctrl.searchPlaceholder);
            } else {
                placeholder = $filter('translate')('SEARCH.CATALOG_PLACEHOLDER');
            }

            return placeholder;
        }

        function destroy() {
            destroyBarcodeWatch && destroyBarcodeWatch();
            destroySearchStringWatch && destroySearchStringWatch();
        }
    }
})();