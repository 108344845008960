(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('surveyPage', {
            template: require('./SurveyPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$stateParams', 'Page', 'ResponsiveService', 'SurveyService',
                'HeaderButtonsFactory', SurveyPageController],
            bindings: {
                surveyData: '<'
            }
        })

    function SurveyPageController($rootScope, $stateParams, Page, ResponsiveService, SurveyService,
                                  HeaderButtonsFactory) {
        const ctrl = this;

        ctrl.TABS = {
            'SURVEY': 0,
            'MANAGER_VIEW': 1
        }
        ctrl.isDesktop = ResponsiveService.isDesktop();

        ctrl.$onInit = init;
        ctrl.changeTab = changeTab;
        ctrl.$onDestroy = destroy;

        function init() {
            $rootScope.$broadcast('getSurveyToken', ctrl.surveyData.SurveyToken);

            if (!ctrl.surveyData.IsManager) {
                ctrl.activeTab = ctrl.TABS.SURVEY;
            } else {
                ctrl.activeTab = $stateParams.tab === 'manager' || ctrl.surveyData.ExpiredSurveyAvailableForManager ? ctrl.TABS.MANAGER_VIEW : ctrl.TABS.SURVEY;
                ctrl.isDesktop && !ctrl.surveyData.ExpiredSurveyAvailableForManager && setHeaderButtons(ctrl);
            }
        }

        function changeTab(tab) {
            ctrl.activeTab = tab;

            Page.stateGo('survey', $stateParams.token, $stateParams.extra, null, null, false, {tab: tab === ctrl.TABS.MANAGER_VIEW ? 'manager' : null});

            if (ctrl.isDesktop) {
                Page.setSubTitle(SurveyService.TAB_TRANSLATIONS[tab === ctrl.TABS.MANAGER_VIEW ? 'MANAGER' : 'SURVEY']);
            }
        }

        function setHeaderButtons(ctrl) {
            Page.setSubTitle(SurveyService.TAB_TRANSLATIONS[$stateParams.tab === ctrl.TABS.MANAGER_VIEW ? 'MANAGER' : 'SURVEY']);
            
            HeaderButtonsFactory.createButtonsList(() => getHeaderButtons(ctrl))

            function getHeaderButtons(ctrl) {
                return [{
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    place: 'title',
                    items: [
                        {
                            title: SurveyService.TAB_TRANSLATIONS.SURVEY,
                            onClick: () => {
                                changeTab(ctrl.TABS.SURVEY)
                            },
                            active: ctrl.activeTab === ctrl.TABS.SURVEY
                        },
                        {
                            title: SurveyService.TAB_TRANSLATIONS.MANAGER,
                            onClick: () => {
                                changeTab(ctrl.TABS.MANAGER_VIEW)
                            },
                            active: ctrl.activeTab === ctrl.TABS.MANAGER_VIEW
                        }
                    ]
                }]
            }
        }

        function destroy() {
            if (ctrl.isDesktop) {
                HeaderButtonsFactory.resetButtonsList()
            }
        }
    }
})();