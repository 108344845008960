(() => {
    angular
        .module('App.Catalog')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('catalogCategory', {
                    parent: 'base',
                    url: '/catalog/category/:token/:extra',
                    template: require('./CatalogCategory.tpl.html'),
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        catalogCategoryData: ['base', '$http', '$stateParams', function (base, $http, $stateParams) {
                            if (base) {
                                return $http.post('/Catalog/GetSubCategoriesAndItems/' + $stateParams.token, {
                                    catalogCategoryToken: $stateParams.extra
                                });
                            }
                        }]
                    },
                    controller: 'CatalogCategoryController as ctrl'
                });
        }])
        .controller('CatalogCategoryController', ['$stateParams', 'catalogCategoryData', 'Page', function ($stateParams, catalogCategoryData, Page) {

            var ctrl = this, breadcrumbsLength;
            
            catalogCategoryData.data.Category.TranslatableEntity.PreferTranslatedVersion && catalogCategoryData.data.Category.TranslatableEntity.LocalizedAlternativeLocale ?
                Page.setTitle(catalogCategoryData.data.Category.TranslatableEntity.LocalizedAlternativeLocale.Title) :
                Page.setTitle(catalogCategoryData.data.Category.TranslatableEntity.LocalizedLocale.Title);
            
            ctrl.catalogCategoryData = catalogCategoryData.data;

            if (_.get($stateParams, 'data.searchString')) {
                ctrl.catalogCategoryData.searchString = $stateParams.data.searchString;
            }

            breadcrumbsLength = catalogCategoryData.data.Breadcrumbs.length;


            if ($stateParams.backState?.options?.showSearch || $stateParams.backState?.state === 'search') {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, $stateParams.backState.options);
            } else if (catalogCategoryData.data.Breadcrumbs && breadcrumbsLength > 1) {
                Page.showBackButton(
                    'catalogCategory',
                    $stateParams.token,
                    catalogCategoryData.data.Breadcrumbs[breadcrumbsLength - 2].CatalogCategoryToken
                );
            } else {
                Page.showBackButton('catalog', $stateParams.token);
            }
        }]);
})();
