(function () {
  'use strict';
  var app = angular.module('App.Elements');

  app.directive('validationMessage', ['$translate', '$timeout',
    function ($translate, $timeout) {
      return {
        restrict: 'E',
        template: require('./ValidationMessage.tpl.html'),
        replace: true,
        scope: {
          errors: '=',
          name: '@',
          replaceBefore: '@',
          replaceAfter: '@'
        },
        link: function (scope, element, attrs) {
          // Init Directive
          initDirective();
          function initDirective() {
            scope.message = null;

            // Listen for when the errors list change
            scope.$watch('errors', function (newVal, oldVal) {
              validateErrors();
            }, true);

            // Do an initial validation
            validateErrors();
          }

          // Methods
          function validateErrors() {
            // reset the value
            scope.message = null;

            if (scope.errors && scope.errors.length) {
              // Grab the error if we can find one
              var error = getError();
              if (error) {
                $translate(error.Message).then(function (translation) {
                  // Should we replace some parts of the text?
                  if (scope.replaceBefore && scope.replaceBefore.length
                    && scope.replaceAfter && scope.replaceAfter.length) {
                    // replace text
                    translation = translation.replace(scope.replaceBefore, scope.replaceAfter);
                  }

                  // Show the message
                  $timeout(function () {
                    scope.message = translation;
                  });
                });
              }
            }
          }

          function getError() {
            if (scope.name && scope.name.length) {
              for (var i = 0; i < scope.errors.length; i++) {
                if (scope.errors[i].Name.toLowerCase() === scope.name.toLowerCase()) {
                  return scope.errors[i];
                }
              }
            }
            return null;
          }
        }
      }
    }
  ]);
})();