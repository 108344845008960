(function () {
    'use strict';
    var app = angular.module('App.Elements');

    app.directive('usergroupSelector', ['$http', '$filter',
        function ($http, $filter) {
            return {
                restrict: 'E',
                template: require('./UserGroupSelector.html'),
                scope: {
                    required: '<',
                    multiple: '<',
                    disabled: '<',
                    model: '=',
                    settings: '=',
                    resetUserGroups: '=?'
                },
                controller: ['$scope', '$element', '$attrs',
                    function ($scope, $element, $attrs) {
                        $scope.model = $scope.model || [];
                        $scope.multiple = $scope.multiple || false;
                        $scope.isLoading = true;
                        $scope.handleChange = handleChange;
                        $scope.resetUserGroups = resetUserGroups;

                        // Init
                        InitDirective();

                        function InitDirective() {
                            var settings = {};
                            if ($scope.settings) {
                                settings = $scope.settings;
                            }

                            settings.selected = $scope.multiple ? $scope.model : [$scope.model];

                            $http.post('/Directives/UserGroupSelector/GetSelector', settings).then(response => {
                                // Success
                                $scope.groups = [...response.data.Selected.map(group => ({
                                    ...group,
                                    isDefault: true
                                })), ...response.data.UserGroups];
                                $scope.groups.model = $scope.multiple ?
                                    $scope.groups.filter(g => $scope.model.find(id => g.UserGroupId === id)) :
                                    $scope.groups.filter(g => g.UserGroupId === $scope.model);
                                $scope.isLoading = false;
                            }, () => {
                                // Failed
                                $scope.isLoading = false;
                            });
                        }

                        function handleChange() {
                            if (!$scope.groups.model) {
                                $scope.model = null;
                                return;
                            }
                            
                            if ($scope.multiple) {
                                $scope.model = $scope.groups.model.map(group => group.UserGroupId);
                            } else {
                                $scope.model = $scope.groups.model[0] && $scope.groups.model[0].UserGroupId;
                            }
                        }

                        function resetUserGroups() {
                            $scope.$$childTail.reset && $scope.$$childTail.reset();
                        }
                    }
                ]
            }
        }
    ]);
})();
