(function () {
    'use strict';
    var app = angular.module('App.Basics');

    app.factory('AuthHttpResponseInterceptor', ['$q', '$injector', '$rootScope',
        function ($q, $injector, $rootScope) {

            function sendTo(goState, params) {
                const state = $injector.get('$state');
                const path = window.location.hash;

                if (params === undefined || params === null) {
                    params = state.params;
                }

                params.path = path.substr(1, path.length);
                state.go(goState, params);
            }

            function maintenanceMessage() {
                $rootScope.$emit('$underMaintenance');
            }

            function errorMessage() {
                $rootScope.$emit('$404error');
            }

            function sendToPin(data) {
                const state = $injector.get('$state');
                if (state.current.name !== 'pin') {
                    const Page = $injector.get('Page');
                    const currentParams = state.params;

                    if (isPinResponse(currentParams) || Page.getNextState() === 'pin') {
                        // return as we've already changed the params to be the pin params, so we expect the transition to not be done yet
                        return;
                    }

                    const PinCodeService = $injector.get('PinCodeService')

                    Page.saveFromPinState(state.current.name, currentParams);
                    PinCodeService.saveLabels(data.PinCodeLabels);

                    $rootScope.ForcePinSkip = false;
                    sendTo('pin', data);
                }
            }

            function isPinResponse(data) {
                return (data && (
                    (data.hasOwnProperty('SetPinCode') && data.SetPinCode === true) ||
                    (data.hasOwnProperty('EnterPinCode') && data.EnterPinCode === true)
                ));
            }

            return {
                response: function (response) {
                    if (response.status === 401) {
                        if (isPinResponse(response.data)) {
                            sendToPin(response.data);
                        } else {
                            sendTo('login');
                        }
                    }
                    if (response.status === 404) {
                        errorMessage();
                    }
                    if (response.status === 503) {
                        maintenanceMessage();
                    }
                    return response || $q.when(response);
                },
                responseError: function (rejection) {
                    if (rejection.status === 401) {
                        if (isPinResponse(rejection.data)) {
                            sendToPin(rejection.data);
                        } else {
                            sendTo('login');
                        }
                    }
                    if (rejection.status === 404) {
                        errorMessage();
                    }
                    if (rejection.status === 503) {
                        maintenanceMessage();
                    }
                    return $q.reject(rejection);
                }
            };
        }
    ]);

})();