(() => {
    angular
        .module('App.Survey')
        .component('surveyManagerTab', {
            template: require('./SurveyManagerTabComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$timeout', 'Page', 'SurveyService', surveyManagerTabController],
            bindings: {
                showSingleDepartment: '<',
                surveyToken: '<',
                departmentToken: '<',
                isVisible: '<'
            }
        })

    function surveyManagerTabController($scope, $element, $timeout, Page, SurveyService) {
        const ctrl = this;

        $scope.$watch('ctrl.isVisible', init);

        function init(isVisible) {
            toggleTabClass(isVisible);

            if (!isVisible)
                return

            !ctrl.isLoaded && SurveyService.getManagerTabData(ctrl.showSingleDepartment, ctrl.surveyToken, ctrl.departmentToken)
                .then(data => {
                    ctrl.departments = data.Departments;
                    ctrl.overallResultValueString = data.OverallResultValueString;
                    ctrl.overallResult = data.OverallResult;
                    ctrl.participationRate = data.ParticipationRate;
                    ctrl.humanDriversWithQuestionScores = data.HumanDriversWithQuestionScores;
                    ctrl.departmentsCount = data.DepartmentsCount;

                    ctrl.ratingClass = SurveyService.getRatingClass(data.RateType);
                    ctrl.ratingIcon = SurveyService.getRatingIcon(data.RateType);

                    data.DepartmentName && Page.setTitle(data.DepartmentName);

                    if (ctrl.showSingleDepartment && ctrl.participationRate) {
                        $timeout(() => initChart());
                    }
                })
                .finally(() => {
                    ctrl.isLoaded = true;
                });
        }

        function initChart() {
            SurveyService.initParticipationRateChart($element.find('.chart-container')[0], ctrl.participationRate)
        }

        function toggleTabClass(isVisible) {
            document.querySelector('.base-view').classList.toggle('base-view-survey-manager-tab', isVisible);
        }
    }
})();