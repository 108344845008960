(() => {
    'use strict';

    angular
        .module('App.Social')
        .factory('SocialService', ['$http', '$filter', SocialService]);

    function SocialService($http, $filter) {

        var decreaseBadgeCountFunction;

        return {
            getSettings: getSettings,
            getPosts: getPosts,
            getSearchResults: getSearchResults,
            getMediaEncodingStatus: getMediaEncodingStatus,
            setDecreaseBadgeCountFunction: setDecreaseBadgeCountFunction,
            getMembers: getMembers,
            getSearchPlaceholder: getSearchPlaceholder,
            destroyData: destroyData,
        };

        function setDecreaseBadgeCountFunction(func) {
            decreaseBadgeCountFunction = func;
        }

        function getSettings(token, socialGroupId, setBadges, socialGroupIsOptional) {
            return $http({
                method: 'get',
                url: '/Social/GetSettings/' + token,
                params: {
                    socialGroupId,
                    setBadges,
                    socialGroupIsOptional
                }
            }).then(resp => resp.data)
        }

        function getPosts(accountModuleToken, socialGroupId, offset = 0, limit = 20, sortType, startDate, endDate) {
            return $http.post('/Social/GetPosts', {
                accountModuleToken,
                offset,
                limit,
                socialGroupId,
                sortType,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate)
            }).then(resp => resp.data);
        }

        function getSearchResults(model) {
            const searchModel = {
                ...model,
                limit: model.limit || 20,
                startDate: formatDate(model.startDate),
                endDate: formatDate(model.endDate)
            }
            return $http.post('/SearchV2/SearchSocial', searchModel).then(resp => resp.data)
        }

        function formatDate(date) {
            return date ? moment(date).format('YYYY-MM-DD[T]HH:mm:ss') : null
        }

        function getMediaEncodingStatus(moduleToken, posts) {
            return $http.post('/Social/GetMediaEncodingStatus/' + moduleToken, {
                socialPostIds: posts.map(post => post.SocialPostId)
            }).then(resp => resp.data);
        }

        function getMembers(socialWallToken, socialGroupId) {
            return $http.post('/Social/GetAvailableUsersForSocialGroup', {
                token: socialWallToken,
                socialGroupId: socialGroupId
            }).then(resp => resp.data.Users)
        }

        function getSearchPlaceholder({PageTitle, PageSubTitle, IsSharedView}) {
            let placeholder = $filter('translate')('CONTENT.SEARCH_IN');

            if (PageSubTitle) {
                placeholder += ' ' + $filter('translate')(PageSubTitle);
            } else {
                placeholder += IsSharedView ? (' ' + $filter('translate')('SOCIAL.ALL')) : (" " + PageTitle);
            }

            return placeholder;
        }

        function destroyData() {
            decreaseBadgeCountFunction = null;
        }
    }
})();
