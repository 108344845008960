(() => {
    angular.module('App.Survey', []);

    require([
        './SurveyController',
        './SurveyPage/SurveyComponent',
        './SurveyPage/SurveyPageComponent',
        './SurveyPage/SurveyService',
        './SurveyPage/FinishPopup/SurveyFinishPopupComponent',

        './SurveyPage/ManagerTab/NoDataAvailable/NoDataAvailableComponent',
        './SurveyPage/ManagerTab/QuestionStatisticPopup/QuestionStatisticPopupComponent',
        './SurveyPage/ManagerTab/SurveySingleDepartmentPageController',
        './SurveyPage/ManagerTab/SurveyManagerTabComponent',
        './SurveyPage/ManagerTab/StoreManagerVew/StoreManagerVewComponent',
        './SurveyPage/ManagerTab/MultipleDepartmentView/MultipleDepartmentViewComponent',
        './SurveyPage/ManagerTab/MultipleDepartmentView/DepartmentRowComponent',

        './Breakdown/SurveyBreakdownComponent',
        './Poll/SurveyPollComponent',
        './Question/SurveyQuestionComponent',
        './Question/SurveyQuestionFactory',

        './SurveyListController',
        './SurveyList/SurveyListComponent'
    ])
})();