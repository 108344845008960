(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('multipleDepartmentViewComponent', {
            template: require('./MultipleDepartmentViewComponent.html'),
            controllerAs: 'ctrl',
            controller: [MultipleDepartmentViewComponentController],
            bindings: {
                isLoaded: '<',
                departments: '<',
                departmentsCount: '<',
                surveyToken: '<'
            }
        })

    function MultipleDepartmentViewComponentController() {
        const ctrl = this;
        let isAsc;

        ctrl.changeSorting = changeSorting;

        function changeSorting() {
            isAsc = !isAsc;

            ctrl.sortingClass = 'fa-duotone ' + (isAsc ? 'ascending' : 'descending');
            ctrl.departments = sort(ctrl.departments, isAsc);
        }

        function sort(arr, isAsc) {
            const listOfNotNumericScore = ["-", "N/A"];
            return arr.sort((a, b) => {
                if (isAsc) {
                    if (listOfNotNumericScore.includes(a.ScoreValueString) && !listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return 1;
                    }

                    if (!listOfNotNumericScore.includes(a.ScoreValueString) && listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return -1;
                    }

                    if (listOfNotNumericScore.includes(a.ScoreValueString) && listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return a.ScoreValueString === b.ScoreValueString
                        ? 0
                        : (a.ScoreValueString > b.ScoreValueString ? -1 : 1);
                    }

                    return b.ScoreValueString - a.ScoreValueString;
                } else {
                    if (listOfNotNumericScore.includes(a.ScoreValueString) && !listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return -1;
                    }

                    if (!listOfNotNumericScore.includes(a.ScoreValueString) && listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return 1;
                    }

                    if (listOfNotNumericScore.includes(a.ScoreValueString) && listOfNotNumericScore.includes(b.ScoreValueString)) {
                        return a.ScoreValueString === b.ScoreValueString
                        ? 0
                        : (a.ScoreValueString > b.ScoreValueString ? 1 : -1);
                    }

                    return a.ScoreValueString - b.ScoreValueString;
                }
            });
        }
    }
})();